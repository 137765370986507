<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        class="row"
                        :label="$t('forms.superior')"
                        label-for="h-superior"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-superior"
                            v-model="employeeSelect"
                            :options="employees"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.employee.givenName.$errors.length }"
                        :label="$t('forms.givenName')"
                        label-for="givenName"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.givenName"
                            id="givenName"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.givenName.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.employee.familyName.$errors.length }"
                        :label="$t('forms.familyName')"
                        label-for="familyName"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.familyName"
                            id="familyName"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.familyName.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.active')"
                        label-for="h-active"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="employee.enabled" id="h-active" switch/>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.employee.email.$errors.length }"
                        :label="$t('forms.email')"
                        label-for="email"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.email"
                            id="email"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.email.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.employee.privateEmail.$errors.length }"
                        :label="$t('forms.privateEmail')"
                        label-for="privateEmail"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.privateEmail"
                            id="privateEmail"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.privateEmail.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.employee.userNumber.$errors.length }"
                        :label="$t('forms.number')"
                        label-for="number"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.userNumber"
                            id="number"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.userNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.3lc')"
                        label-for="userLetterCode"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.userLetterCode"
                            id="userLetterCode"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.companyTelephone')"
                        label-for="companyTelephone"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.companyTelephone"
                            id="companyTelephone"
                        />

                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.privateTelephone')"
                        label-for="privateTelephone"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.privateTelephone"
                            id="privateTelephone"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.function')"
                        label-for="function"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.companyPosition"
                            id="function"
                        />

                    </b-form-group>
                </b-col>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.employee.address.streetAddress.$errors.length }"
                        :label="$t('forms.address')"
                        label-for="address"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.address.streetAddress"
                            id="address"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.address.streetAddress.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.placeResidence')"
                        label-for="city"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.address.city"
                            id="city"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.mailingAddress')"
                        label-for="locality"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.address.locality"
                            id="locality"
                        />
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.employee.startOn.$errors.length }"
                        :label="$t('forms.startOn')"
                        label-for="h-start"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="start"
                            v-model="employee.startOn"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.startOn.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.endOn')"
                        label-for="h-end"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="end"
                            v-model="employee.endOn"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                            class="form-input"
                        />

                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.employee.nationality.$errors.length }"
                        :label="$t('forms.nationality')"
                        label-for="nationalitySelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="nationalitySelect"
                            v-model="employee.nationality"
                            :options="nationalities"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.nationality.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.employee.birthdate.$errors.length }"
                        :label="$t('forms.birthdate')"
                        label-for="h-birthdate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="birthdate"
                            v-model="employee.birthdate"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.employee.birthdate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.measurements')"
                        label-for="measurements"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="employee.measurements"
                            id="measurements"
                        />

                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.branchSelect.$errors.length }"
                        :label="$t('forms.branch')"
                        label-for="branchSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="branchSelect"
                            v-model="branchSelect"
                            :options="branches"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.branchSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.branchSelect.$errors.length }"
                        :label="$t('forms.department')"
                        label-for="departmentSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="departmentSelect"
                            v-model="departmentSelect"
                            :options="departments"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.departmentSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        employeeObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getUserList({params: {'role': "ROLE_EMPLOYEE"}}, this.employees)
        this.getResourceList(this.$Departments, this.departments)
        this.getResourceList(this.$Stations, this.stations)
        this.getResourceList(this.$Branches, this.branches)
        if ('id' in this.employee) {
            if (this.employee.station) {
                this.stationSelect = {
                    label: this.employee.station.name,
                    id: this.employee.station['@id'],
                };
            }
            if (this.employee.department) {
                this.departmentSelect = {
                    label: this.employee.department.name,
                    id: this.employee.department['@id']
                }
            }
            if (this.employee.branch) {
                this.branchSelect = {
                    label: this.employee.branch.name,
                    id: this.employee.branch['@id']
                }
            }
            if (this.employee.superior) {
                this.employeeSelect = {
                    label: this.employee.superior.givenName  + ' ' + this.employee.superior.familyName,
                    id: this.employee.superior['@id']
                }
            }
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            departmentSelect: {required},
            branchSelect: {required},
            employee: {
                userNumber: {required},
                givenName: {required},
                familyName: {required},
                email: {required, email},
                privateEmail: {email},
                nationality: {required},
                address: {
                    streetAddress: {required}
                },
                startOn: {required},
                birthdate: {required}
            },
        };
    },
    data() {
        return {
            employee: this.employeeObject,
            employees: [],
            employeeSelect: null,
            departments: [],
            departmentSelect: {},
            branches: [],
            branchSelect: {},
            nationalitySelect: "",
            nationalities: this.$helper.getCountriesList(),
            stationSelect: {},
            stations: []
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.employee.department = this.departmentSelect.id
                this.employee.branch = this.branchSelect.id
                if (this.employeeSelect) {
                    this.employee.superior = this.employeeSelect.id
                }
                if (this.stationSelect) {
                    this.employee.station = this.stationSelect.id
                }
                if (this.employee.birthdate) {
                    this.employee.birthdate.setTime(this.employee.birthdate.getTime() + (12*60*60*1000))
                }
                if (this.employee.startOn) {
                    this.employee.startOn.setTime(this.employee.startOn.getTime() + (12*60*60*1000))
                }
                if (this.action === "add") {
                    this.create(this.$Users, this.employee, this.$t("messages.employeeCreated"), null, this.success, this.error);
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(this.$Users, this.employee.id, this.employee, this.$t("messages.employeeUpdated"), null, this.success, this.error);
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/employees`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

